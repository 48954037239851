import React from 'react';
import Home from './components/Home/Home';
import Menu from "./components/Menu/Menu";
import logo from './logo.png';
import './Construct.css';
import axios from "axios";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

function App() {

    axios.get('https://www.natwebdev.com/api').then((res)=>{
        console.log(res);
    })

  return (
      <Router>
        <div className="App">
          <div id="page">
              <Menu/>
            <img src={logo} />
              <Switch>
                  <Route path="/home">
                    <Home/>
                  </Route>
              </Switch>
          </div>
        </div>
      </Router>
  );
}

export default App;
