import React from "react";
import './Menu.css';

class Menu extends React.Component{

    render(){
        return(<nav>
                {/*<ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">A propos</a></li>
                </ul>*/}
            </nav>
        );
    }
}

export default  Menu;